import {
  Avatar,
  Badge,
  Flex,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

function TablesTableRow({
  ID,
  name,
  email,
  phone_number,
  username,
  isCompletedKYC,
  plan,
  expoToken,
  date_register,
  isLast,
}) {
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {ID}
        </Text>
      </Td>
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar w="50px" borderRadius="100px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {email}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {username}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {phone_number}
          </Text>
        </Flex>
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date_register}
        </Text>
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Tooltip
          hasArrow
          label={"Token de Notificación: " + expoToken}
          fontSize="sm"
        >
          <Badge
            bg={expoToken == "NO TIENE" ? "gray.400" : "green.500"}
            color={expoToken == "NO TIENE" ? "white" : "white"}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {!expoToken || expoToken == "NO TIENE" ? "Inactivo" : "Activo"}
          </Badge>
        </Tooltip>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Badge
          bg={plan ? "green.400" : bgStatus}
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {plan || "Inactivo"}
        </Badge>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {isCompletedKYC ? "SI" : "NO"}
        </Text>
      </Td>

      {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null} style={{ height: '100%', justifyContent: 'center' }}>
        <Button p="0px" bg="transparent" variant="no-effects">
          <AiFillDelete
            size={22}
            color="#EF4237"
          />
        </Button>
      </Td> */}
    </Tr>
  );
}

export default TablesTableRow;
